/*
 * @Author: wangqs
 * @Email: wqs565@163.com
 * @Date: 2018-05-21 10:50:30
 * @Last Modified by: wangqs
 * @Last Modified time: 2021-05-26 16:29:23
 * @Description: axios 基础配置
 */

export default {
  method: 'post',
  // 基础url前缀
  baseURL: '',
  // 请求头信息
  // headers: {
  //   // 'Content-Type':'application/json;charset=UTF-8'
  //   'Content-Type': 'application/x-www-form-urlencoded'
  // },
  // 参数
  data: {},
  // 设置超时时间
  timeout: 10000,
  // 携带凭证
  withCredentials: false,
  // 返回数据类型
  responseType: 'json'
}
