export default {
  install (Vue) {
    // 深克隆
    Vue.prototype.$deepClone = function (obj) {
      if (typeof obj !== 'object' || obj === null) {
        return obj
      }
      // 特殊的引用类型处理
      switch(Object.prototype.toString.call(obj).slice(8, -1)) {
        case 'Date': 
          return new Date(obj);
        case 'RegExp': 
          return new RegExp(obj);
        case 'Boolean':
          return new Boolean(obj);
      }
      let newObj = obj instanceof Array ? [] : {}
      for (let key in obj) {
        if (obj && obj.hasOwnProperty(key)) {
          newObj[key] = typeof obj[key] === 'object'
            ? this.$deepClone(obj[key])
            : obj[key]
        }
      }
      return newObj
    }
    /**
    * 微信聊天详情 时间戳转时间格式
    * value 毫秒 2019-05-22 12:08:20
    */
     Vue.prototype.getTimeStr = (value, flag) => {
      if (!value) {
        return ''
      }
      value = parseInt(value)
      let date = new Date(value)
      let Y = date.getFullYear() + '-'
      let M = (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) + '-'
      let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() +
        ' '
      let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() +
        ':'
      let m = date.getMinutes() < 10
        ? '0' + date.getMinutes() + ':'
        : date.getMinutes() + ':'
      let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      console.log(Y + M + D, '日期')
      if (flag === 'day') {
        return (Y + M + D).replace(/ /g, '')
      } else if (flag === 'timeHourMin') {
        return (h + m).slice(0, -1)
      } else {
        return Y + M + D + h + m + s
      }
    }
    // 价格转换
    Vue.prototype.changePriceNumber = (num) => {
      return parseFloat(num || 0).toFixed(2)
    }
    // 全局变量
    Vue.prototype.VUE_APP_STATIC_URL = process.env.VUE_APP_STATIC_URL

  }
}