import Vue from 'vue'
import Router from 'vue-router'
import Routes from './routes'
import $http from '../axios/interface.js';

Vue.use(Router)

const router = new Router({
  // mode: 'history',
  // base: '/qw-scrm/',
  scrollBehavior: () => ({ y: 0 }),
  routes: Routes
})
var isFirst = true
async function sdkInit () {
  let wxres = await $http.userApi.signatureGet({url: window.location.href})
  let wxConfig = wxres.msg || {}
  window.localStorage.setItem('wxres', JSON.stringify(wxres))
  return new Promise((resolve, reject) => {
    if (wxres.code === 200) {
      try {
        let config = {
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: wxConfig.appid, // 必填，公众号的唯一标识
          timestamp: wxConfig.timestamp, // 必填，生成签名的时间戳
          nonceStr: wxConfig.nonceStr, // 必填，生成签名的随机串
          signature: wxConfig.signature,// 必填，签名
          jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表
        }
        console.log(config, 'config')
        wx.config(config);
        wx.ready(function () {
          resolve()
        })
      } catch (e) {
        console.log(e)
        reject()
      }
    } else {
      reject()
    }
  })
}
// beforeEach
router.beforeEach(async (to, from, next) => {
  // 设置标题
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (isFirst) {
    isFirst = false
    // 注入wxconfig
    if (window.location.href.indexOf('?code=') === -1 && process.env.NODE_ENV !== 'development') {
      // sdkInit().then(() => {
      //   next()
      // }).catch(() => {
      //   next()
      // })
      next()
    } else {
      console.log('r init else')
      next()
    }
  } else {
    console.log('r else')
    next()
  }
})

export default router
