/*
 * @Author: wangqs
 * @Email: wqs565@163.com
 * @Date: 2018-05-21 10:33:04
 * @Last Modified by: wangqs
 * @Last Modified time: 2023-10-17 14:22:25
 * @Description: 网络拦截器等
 */

// 封装axios请求，官网：https://www.npmjs.com/package/axios
import axios from 'axios'

import config from './config.js'

import { Toast } from 'vant' // 信息提示

import store from '@/store/index'
import errorCn from './errorCn'
// 获取页面参数
// import { getUrlParam } from '@/utils/common'

// const href = window.location.href
let count = 0
// let loading
function hideLoading () {
  count--
  if (count === 0) {
    store.commit('setFullscreenLoading', { status: false, text: '' })
  } else if (count < 0) {
    count = 0
    store.commit('setFullscreenLoading', { status: false, text: '' })
  }
}
export default function $axios (options) {
  if (options.loading === 'hide') {
  } else {
    count++
    store.commit('setFullscreenLoading', { status: true, text: '' })
  }
  return new Promise((resolve, reject) => {
    let userData = JSON.parse(window.localStorage.getItem('userData') || '{}')
    // 本地测试使用的token
    let testToken = 'LH2RqZ1+CxYh4GbdHi7c0aiWLMcXZXorqAMWU0bw+xY=' // pb/Id0Nsv6kLqKCRwhMm6XC4Aakmo3lW5SYwOqpCTFs=
    let token = userData.token || ''
    let loginUrl = process.env.NODE_ENV === 'development' ? window.location.origin : 'https://growth.tjerong.cn'
    if (testToken && process.env.NODE_ENV === 'development') {
      token = testToken
    }
    const instance = axios.create({
      baseURL: config.baseURL,
      headers: {
        'Content-Type': 'application/json',
        token
      },
      transformResponse: [function (data) {
      }]
    })
    instance.interceptors.request.use(
      config => {
        // Tip: 1
        // 请求开始的时候可以结合 vuex 开启全屏的 loading 动画

        // Tip: 2
        // 带上 token , 可以结合 vuex 或者重 localStorage
        // if (store.getters.token) {
        //     config.headers['X-Token'] = getToken() // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
        // } else {
        //     // 重定向到登录页面
        // }

        // Tip: 3
        // 根据请求方法，序列化传来的参数，根据后端需求是否序列化
        // if (config.method.toLocaleLowerCase() === 'post' ||
        //   config.method.toLocaleLowerCase() === 'put' ||
        //   config.method.toLocaleLowerCase() === 'delete' ||
        //   config.method.toLocaleLowerCase() === 'get') {
        //   // 营销商学院跟进记录地址
        //   if (href.indexOf('/contactRecordForMarketing') > -1) {
        //     config.url += '?sessionId=' + getUrlParam('sessionId') || ''
        //     // 请求 session 验证
        //   } else if (config.url.indexOf('/login') > -1 || config.noSid) {
        //     // config.data.sessionId = 'test-sessionid'
        //   } else {
        //     // 必要参数
        //     if (store.state.user.sid) {
        //       config.url += '?sessionId=' + (store.state.user.sid || '')
        //     } else {
        //       // todo
        //       return Promise.reject(new Error('no-session'))
        //     }
        //   }
        // }
        return config
      },
      error => {
        // 请求错误时做些事(接口错误、超时等)
        // Tip: 4
        // 关闭loadding

        //  1.判断请求超时
        if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
          // console.log('根据你设置的timeout/真的请求超时 判断请求现在超时了，你可以在这里加入超时的处理方案')
          // return service.request(originalRequest);//例如再重复请求一次
        }
        return Promise.reject(error) // 在调用的那边可以拿到(catch)你想返回的错误信息
      }
    )

    instance.interceptors.response.use(
      response => {
        let data = {}
        // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
        if (response.data === undefined) {
          data = response.request.responseText
        } else {
          data = response.data
        }
        try {
          data = JSON.parse(data)
        } catch (e) {
          // console.log(e)
        }
        // 根据返回的code值来做不同的处理（和后端约定）
        switch (data.code) {
          case 200:
            return data
          default:
            var str = data.errMsg || data.msg || '服务异常'
            if (data.code == '888888') {
              str = '登录失效，请重新登录'
              setTimeout(() => {
                window.location.href = `https://growth.tjerong.cn/growth/gateway/wx/oauth2?appId=wx6815d091476ccf20&redirectUrl=${loginUrl}&state=home-index`
              }, 1500)
            } else if (data.code === 500) {
              str = '后端同学开小差了~'
            } 
            Toast(errorCn[str] || str)
            console.error(str)
            break
        }
        return data
      },
      err => {
        if (err && err.response) {
          switch (err.response.status) {
            case 400:
              err.message = '请求错误'
              break

            case 401:
              err.message = '未授权，请登录'
              window.location.href = `https://growth.tjerong.cn/growth/gateway/wx/oauth2?appId=wx6815d091476ccf20&redirectUrl=${loginUrl}&state=home-index`
              break

            case 403:
              err.message = '拒绝访问'
              break

            case 404:
              err.message = `请求地址出错: ${err.response.config.url}`
              break

            case 408:
              err.message = '请求超时'
              break

            case 500:
              err.message = '服务器内部错误'
              break

            case 501:
              err.message = '服务未实现'
              break

            case 502:
              err.message = '网关错误'
              break

            case 503:
              err.message = '服务不可用'
              break

            case 504:
              err.message = '网关超时'
              break

            case 505:
              err.message = 'HTTP版本不受支持'
              break

            default:
          }
        }
        // console.log(err)
        // 请求超时
        if (err.code === 'ECONNABORTED' && err.message.indexOf('timeout') !== -1) {
          // console.log('根据你设置的timeout/真的请求超时 判断请求现在超时了，你可以在这里加入超时的处理方案')
          Toast('请求超时')
          // return service.request(originalRequest);//例如再重复请求一次
        } else {
          Toast(`${err}`)
        }
        return Promise.reject(err) // 返回接口返回的错误信息
      }
    )

    // 请求处理
    instance(options)
      .then((res) => {
        if (options.loading !== 'hide') {
          hideLoading()
        }
        typeof res === 'string' ? resolve(JSON.parse(res)) : resolve(res)
      })
      .catch((error) => {
        if (options.loading !== 'hide') {
          hideLoading()
        }
        if (error !== 'no-sessionId') {
          reject(error)
        }
        // error === 'no-sessionId' ? '' : reject(error)
      })
  })
}
