/*
 * @Author: xiaokun
 * @Date: 2019-12-24 09:51:06
 * @LastEditors  : xiaokun
 * @LastEditTime : 2019-12-24 22:01:23
 * @Description:
 */
import RouterModules from './modules'
const commonRoute = [
  {
    path: '/',
    redirect: '/home/index',
    name: 'root',
    component: () =>
      import(/* webpackChunkName: "layout" */ '@/views/layout/index.vue'),
    children: [...RouterModules]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
    meta: {
      title: '登录'
    }
  },
]
export default commonRoute
