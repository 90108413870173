/*
 * @Author: wangqs 
 * @Date: 2021-06-21 16:20:34 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-12-30 15:39:53
 */
export default [
  {
    path: '/user/index',
    name: 'chat',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/index.vue'),
    meta: {
      title: '个人中心'
    }
  },
  {
    path: '/user/check',
    name: 'check',
    component: () => import(/* webpackChunkName: "check" */ '@/views/user/check.vue'),
    meta: {
      title: '核销信息'
    }
  },
  {
    path: '/user/bank',
    name: 'bank',
    component: () => import(/* webpackChunkName: "bank" */ '@/views/user/bank/index.vue'),
    meta: {
      title: '我的银行卡'
    }
  },
  {
    path: '/user/bankInfo',
    name: 'bank-info',
    component: () => import(/* webpackChunkName: "bank-info" */ '@/views/user/bank/info.vue'),
    meta: {
      title: '我的银行卡'
    }
  },
  {
    path: '/user/feedback',
    name: 'feedback',
    component: () => import(/* webpackChunkName: "feedback" */ '@/views/user/other/feedback.vue'),
    meta: {
      title: '意见反馈'
    }
  },
  {
    path: '/user/student',
    name: 'student',
    component: () => import(/* webpackChunkName: "student" */ '@/views/user/student/list.vue'),
    meta: {
      title: '学员信息'
    }
  },
  {
    path: '/user/addStudent',
    name: 'addStudent',
    component: () => import(/* webpackChunkName: "addStudent" */ '@/views/user/student/addStudent.vue'),
    meta: {
      title: '添加学员'
    }
  },
  {
    path: '/user/info',
    name: 'info',
    component: () => import(/* webpackChunkName: "info" */ '@/views/user/other/info.vue'),
    meta: {
      title: '个人信息'
    }
  },
  {
    path: '/user/repay',
    name: 'repayment',
    component: () => import(/* webpackChunkName: "repayment" */ '@/views/user/repayment/index.vue'),
    meta: {
      title: '还款'
    }
  },
  {
    path: '/user/repayHistory',
    name: 'repayHistory',
    component: () => import(/* webpackChunkName: "repayHistory" */ '@/views/user/repayment/historyList.vue'),
    meta: {
      title: '历史账单'
    }
  },
  {
    path: '/user/allRepay',
    name: 'allRepay',
    component: () => import(/* webpackChunkName: "allRepay" */ '@/views/user/repayment/allRepay.vue'),
    meta: {
      title: '全部还款'
    }
  },
  {
    path: '/user/repayHistoryDetail',
    name: 'repayHistoryDetail',
    component: () => import(/* webpackChunkName: "repayHistoryDetail" */ '@/views/user/repayment/historyDetail.vue'),
    meta: {
      title: '历史账单详情'
    }
  },
  {
    path: '/user/waitRepay',
    name: 'waitRepay',
    component: () => import(/* webpackChunkName: "waitRepay" */ '@/views/user/repayment/waitRepay.vue'),
    meta: {
      title: '全部待还'
    }
  }
]