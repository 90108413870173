export default [
  {
    path: '/org/settled',
    name: 'org-settled',
    component: () => import(/* webpackChunkName: "org-settled" */ '@/views/orgSettled/index.vue'),
    meta: {
      title: '机构入驻'
    }
  },
  {
    path: '/org/period',
    name: 'org-period',
    component: () => import(/* webpackChunkName: "org-period" */ '@/views/orgPeriod/index.vue'),
    meta: {
      title: '机构分期'
    }
  },
  {
    path: '/org/order',
    name: 'org-period-list',
    component: () => import(/* webpackChunkName: "org-period-list" */ '@/views/orgPeriod/periodList.vue'),
    meta: {
      title: '机构分期订单'
    }
  },
  {
    path: '/org/addContract',
    name: 'org-contract',
    component: () => import(/* webpackChunkName: "org-add-contract" */ '@/views/orgPeriod/contract/add.vue'),
    meta: {
      title: '机构分期添加合同'
    }
  },
  {
    path: '/guarantee/person',
    name: 'org-guarantee-person',
    component: () => import(/* webpackChunkName: "org-guarantee-person" */ '@/views/orgPeriod/guarantee/person.vue'),
    meta: {
      title: '机构分期'
    }
  },
  {
    path: '/guarantee/org',
    name: 'org-guarantee-org',
    component: () => import(/* webpackChunkName: "org-guarantee-org" */ '@/views/orgPeriod/guarantee/org.vue'),
    meta: {
      title: '机构分期'
    }
  }
]