/**
 * 订单
 * Created by wqs565 on 2020/5/18 14:23.
 */
import axios from '../../api'
// 获取订单信息
export const getOrderInfo = data => {
  return axios({
    url: 'gateway/order/getOrderInfo',
    method: 'post',
    data
  })
}
// 校验订单
export const orderCheck = data => {
  return axios({
    url: 'gateway/order/check',
    method: 'post',
    data
  })
}
// 获取支付信息
export const getPayInfo = data => {
  return axios({
    url: 'growth/gateway/index/order',
    method: 'post',
    data
  })
}


// 校验订单v2
export const orderCheckV2 = data => {
  return axios({
    url: 'gateway/order/check/v2',
    method: 'post',
    data
  })
}

// 校验绑卡
export const orderBankCheck = data => {
  return axios({
    url: 'gateway/order/bank/check',
    method: 'post',
    data
  })
}

// 分期订单
export const orderCreate = data => {
  return axios({
    url: 'gateway/order/create',
    method: 'post',
    data
  })
}

// 全款下单前
export const checkFullOrder = data => {
  return axios({
    url: 'gateway/order/checkFullOrder',
    method: 'post',
    data
  })
}

// 全款订单
export const orderAdd = data => {
  return axios({
    url: 'gateway/order/add',
    method: 'post',
    data
  })
}

// 订单列表
export const orderList = data => {
  return axios({
    url: 'gateway/order/query',
    method: 'post',
    data
  })
}

// 获取订单详情
export const orderDetail = data => {
  return axios({
    url: 'gateway/order/detail',
    method: 'post',
    data
  })
}

// 修改订单分期信息
export const orderEdit = data => {
  return axios({
    url: 'gateway/order/edit',
    method: 'post',
    data
  })
}

// 取消订单
export const orderCancel = data => {
  return axios({
    url: 'gateway/order/cancel',
    method: 'post',
    data
  })
}

// 全款订单
export const fullOrder = data => {
  return axios({
    url: 'gateway/order/full/query',
    method: 'post',
    data
  })
}

// 签署合同
export const orderSendCode = data => {
  return axios({
    url: 'gateway/order/sms/send',
    method: 'post',
    data
  })
}

// 签署合同
export const orderConfirm = data => {
  return axios({
    url: 'gateway/order/confirm',
    method: 'post',
    data
  })
}

// 校验是否需要人脸识别
export const orderFaceCheck = data => {
  return axios({
    url: 'gateway/order/face/check',
    method: 'post',
    data
  })
}

// 获取人脸识别参数
export const orderFaceParams = data => {
  return axios({
    url: 'gateway/order/face',
    method: 'post',
    data
  })
}

// 获取机构人脸识别参数
export const orderFaceOrgParams = data => {
  return axios({
    url: 'gateway/order/org/face',
    method: 'post',
    data
  })
}

// 身份证认证
export const orderOrc = data => {
  return axios({
    url: 'gateway/order/ocr',
    method: 'post',
    data
  })
}

// 银行卡识别 gateway/order/ocr/bank
export const bankCardOrc = data => {
  return axios({
    url: 'gateway/order/ocr/bank',
    method: 'post',
    data
  })
}
