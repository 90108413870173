<template>
  <div
    id="app"
    ref="app"
    class="app-box"
  >
    <router-view />
  </div>
</template>

<script>
import { mapMinxin } from './utils/map.js' // 引入地图混入
import sdk from './utils/wx-sdk.js'
export default {
  name: 'App',
  components: {
  },
  mixins: [mapMinxin],
  async created () {
    //判断是否支持 获取本地位置
    if (navigator.geolocation) {
      var n = navigator.geolocation.getCurrentPosition(function(res){
        console.log(res, '经纬度'); // 需要的坐标地址就在res中
      });
    } else {
      console.log('该浏览器不支持定位');
    }
    console.log(sdk, 'sdk')
    await sdk.initJsSdk()
    sdk.wxShare({
      link: window.location.href,
      title: '测试title',
      desc: '测试描述',
      imgUrl: 'https://img-qtj.tjerong.cn/growth/auth/edf6e5f5-e250-4dfc-9e3c-b4a8f8866850.png'
    })
    // await this.remogeo()
    // this.initData()
  }
}
</script>

<style lang="scss">
html,
body {
  height: 100%;
  background-color: #f7f7f7;
  color: #262626;
  position: relative;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  .van-dialog {
    border-radius: 4px;
  }

  .van-pull-refresh {
    user-select: text;
  }
}

.el-picker-panel {
  position: absolute !important;

  .el-time-spinner__list::after,
  .el-time-spinner__list::before {
    height: 73px;
  }
}

.van-toast {
  z-index: 10000 !important;
}

.app-box {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
