/**
 * 首页
 * Created by wqs565 on 2020/5/18 14:23.
 */
import axios from '../../api'
// 预约试听
export const getClassifies = data => {
  return axios({
    url: 'growth/gateway/index/classifies',
    method: 'post',
    data
  })
}

// 课程列表
export const courseList = data => {
  return axios({
    url: 'growth/gateway/index/courses',
    method: 'post',
    data
  })
}

// 意见反馈
export const feedback = data => {
  return axios({
    url: 'gateway/index/feedback',
    method: 'post',
    data
  })
}

// 首页banner和分类信息
export const bannerInfo = data => {
  return axios({
    url: 'growth/gateway/index/banners',
    method: 'post',
    data
  })
}

// 门店详情
export const schoolDetail = data => {
  return axios({
    url: 'growth/gateway/index/course/detail',
    method: 'post',
    data
  })
}

// 门店列表
export const schoolList = data => {
  return axios({
    url: 'growth/gateway/index/courses',
    method: 'post',
    data
  })
}

// 搜索历史
export const searchHistory = data => {
  return axios({
    url: 'growth/gateway/index/search/logs',
    method: 'post',
    data
  })
}
// 清空搜索历史
export const searchDelete = data => {
  return axios({
    url: 'growth/gateway/index/search/del',
    method: 'post',
    data
  })
}

export const getUserInfo = data => {
  return axios({
    url: 'growth/gateway/index/user',
    method: 'post',
    data
  })
}

// 获取个人信息
export const getPersonalInfo = data => {
  return axios({
    url: 'growth/gateway/personal/info',
    method: 'post',
    data
  })
}
