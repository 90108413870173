import Vue from 'vue'
import App from './App.vue'
import store from '@store'
import router from '@router'
import Vant from 'vant';
import api from './axios'
import 'vant/lib/index.css';

import '@assets/styles/index.scss'
import utils from '@utils/index.js'
import toAuth from './utils/toAuth'
import '@utils/zoom'
import VConsole from 'vconsole'

toAuth()
Vue.config.productionTip = false
Vue.use(utils);
Vue.use(Vant);
Vue.use(api)
if (process.env.NODE_ENV === 'development') {
 const vConsole = new VConsole()
  Vue.use(vConsole) 
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
