import axios from "axios"
export default {
  debug: false,

  setJsSdkUrl (sdkUrl = '//res.wx.qq.com/open/js/jweixin-1.2.0.js') {
    // 判断是否为新版本的sdk
    return new Promise((resolve, reject) => {
      if (window.wx.ready) {
        console.log('window.wx已存在，跳过手动设置微信sdk url')
        resolve()
      } else {
        if (sdkUrl) {
          console.log('手动设置微信sdk url-【开始】')

          // 判断是否为新版本的sdk
          const version = sdkUrl.split('jweixin-')[1]
          let isNewVersionSdk = version[0] >= 2 || version[2] >= 4

          console.log('当前sdk版本为：' + version)
          console.log('是否为新版：' + isNewVersionSdk)

          if (!sdkUrl.match('http')) sdkUrl = location.protocol + sdkUrl
          const script = document.createElement('script')

          if (script.readyState) {
            // IE
            script.onreadystatechange = () => {
              if (
                script.readyState === 'loaded' ||
                script.readyState === 'complete'
              ) {
                script.onreadystatechange = null
                if (window.wx) {
                  console.log('手动设置微信sdk url-【成功】')
                  resolve(true)
                } else {
                  const err = new Error(
                    '微信jssdk引入失败，可能是sdk url错误，请正确引入！官方文档：https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#3'
                  )
                  reject(err)
                }
              }
            }
          } else {
            // 其他浏览器
            script.onload = () => {
              if (window.wx) {
                console.log('手动设置微信sdk url-【成功】')
                resolve()
              } else {
                const err = new Error(
                  '微信jssdk引入失败，可能是sdk url错误，请正确引入！官方文档：https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#3'
                )
                reject(err)
              }
            }
          }

          script.setAttribute('src', sdkUrl)
          script.setAttribute('data-namespace', sdkUrl + '-' + new Date().getTime())
          document.body.appendChild(script)
        } else {
          const err = new Error('微信sdk url为空，手动设置微信sdk url-【失败】')
          reject(err)
        }
      }
    })
  },

  async initJsSdk () {
    console.log('进入了')
    await this.setJsSdkUrl()
    console.log('进入了2')
    const { success, data } = await this.getJsSdkSignConfig()
    if (success) {
      wx.config({
        // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        debug: this.debug,

        // 必填，需要使用的JS接口列表
        jsApiList: [
          'checkJsApi',
          'updateTimelineShareData', // 1.4.0新版本的分享朋友圈接口，注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
          'updateAppMessageShareData', // 1.4.0新版本的分享给朋友接口,注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
          'onMenuShareAppMessage', // 老版本分享朋友接口。
          'onMenuShareTimeline', // 老版本分享朋友圈接口。
          'showMenuItems',
          'hideMenuItems'
        ],

        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature // 必填，签名，见附录1
      })

      // 必须放在 回调函数里，不然当在注册的时候 ，有可能还没有返回数据回来，例如刷新问题
      // 微信检查接口列表
      wx.checkJsApi({
        // 需要检测的JS接口列表
        jsApiList: [
          'checkJsApi',
          'updateTimelineShareData', // 新版本：分享给朋友圈
          'updateAppMessageShareData', // 新版本：分享给朋友
          'onMenuShareAppMessage', // 旧版本：分享给朋友
          'onMenuShareTimeline', // 旧版本：分享给朋友圈
          'showMenuItems',
          'hideMenuItems'
        ]
      })

      // 释放未调用的sdk api
      // let sdkPendingList = getApp().globalData.sdkPendingList
      // if (sdkPendingList.length) {
      //   sdkPendingList.forEach(install => {
      //     install()
      //   })
      //   getApp().globalData.sdkPendingList = []
      // }
    }

    // 微信预加载失败回调
    wx.error(res => console.log(res))
  },

  async getJsSdkSignConfig () {
    let authCallbackUrlParams = window.localStorage.getItem('authCallbackUrlParams')
    let authUserInfo = window.localStorage.getItem('authUserInfo')
    let appId = (authCallbackUrlParams && authCallbackUrlParams.appId) || (authUserInfo && authUserInfo.appId)
    let signUrl = location.href.split('#')[0]
    // if (!appId) {
    //   return { success: false }
    // }
    let params = {
      signUrl,
      companyId: 'common.getCompanyId()',
      appId
    }
    let resA = {}

    await axios.get('https://mk-api-test.dustess.com/wxopen/v1/wxpublic/jsSDKSign?signUrl=https%3A%2F%2Fwwf6d793ceba440218-qw-scrm-test.dustess.com%2Fqw-wxmini-mall%2F&companyId=W00000000109&appId=wx965ef62990b09099').then(res => {
      resA = res
    })

    // const { success, data } = await MkWxopenApi.wxpublicJsSDKSignGet(params)

    // return { success, data }
    return resA.data

  },

  async getQwSdkSignConfig () {
    let user = window.localStorage.getItem('user', localStorage)
    let signUrl = location.href.split('#')[0]
    let params = {
      signUrl,
      corpId: user.corpId,
      agentId: user.agentId
    }
    const { success, data } = await MkWxWorkApi.jsSdkSignGet(params)

    return { success, data }
  },

  /**
   * @description: 企微和微信，自定义分享
   * @param {object} shareData
   * @param {function} callBack
   * @return {*}
   */
  wxShare (shareData, callBack) {
    // let href = window.location.href
    // let link = shareData.link
    // history.replaceState({}, shareData.title, link)
    if (!wx.ready) return this.setPendingList((shareData, callBack) => { this.wxShare(shareData, callBack) })

    wx.ready(() => {
      wx.showMenuItems({
        menuList: [
          'menuItem:share:appMessage',
          'menuItem:share:timeline',
          'menuItem:share:qq',
          'menuItem:share:weiboApp',
          'menuItem:favorite',
          'menuItem:share:facebook',
          'menuItem:share:QZone'
        ]
      })

      wx.onMenuShareAppMessage({
        ...shareData,
        success () {
          // 用户点击了分享后执行的回调函数
          console.log('旧版本：分享给朋友【成功】')

          console.log(shareData)
          if (this.debug) alert('分享给朋友【成功】：' + JSON.stringify(shareData))

          // 调用传进来的回调函数
          if (typeof callBack === 'function') {
            callBack({
              success: true,
              type: 'friend',
              shareData: shareData
            })
          }
        },
        cancel () {
          console.log('旧版本：【取消】分享给朋友')

          console.log(shareData)
          if (this.debug) alert('【取消】分享给朋友：' + JSON.stringify(shareData))

          // 调用传进来的回调函数
          if (typeof callBack === 'function') {
            callBack({
              success: false,
              type: 'friend',
              shareData: shareData
            })
          }
        }
      })

      // 分享到朋友圈
      wx.onMenuShareTimeline({
        ...shareData,
        success () {
          // 用户点击了分享后执行的回调函数
          console.log('旧版本：分享到朋友圈【成功】')

          console.log(shareData)
          if (this.debug) {
            alert('分享到朋友圈【成功】：' + JSON.stringify(shareData))
          }

          // 调用传进来的回调函数
          if (typeof callBack === 'function') {
            callBack({
              success: true,
              type: 'friendCircle',
              shareData: shareData
            })
          }
        },
        cancel () {
          console.log('旧版本：【取消】分享到朋友圈')

          console.log(shareData)
          if (this.debug) {
            alert('取消】分享到朋友圈：' + JSON.stringify(shareData))
          }

          // 调用传进来的回调函数
          if (typeof callBack === 'function') {
            callBack({
              success: false,
              type: 'friendCircle',
              shareData: shareData
            })
          }
        }
      })
    })
    // history.replaceState({}, shareData.title, href)

    // 微信预加载失败回调
    wx.error(res => console.log(res))
  },

  hideShareMenu () {
    if (!wx.ready) return this.setPendingList(() => { this.hideShareMenu() })
    wx.ready(() => {
      setTimeout(() => {
        wx.hideMenuItems({
          menuList: [
            'menuItem:share:appMessage',
            'menuItem:share:timeline',
            'menuItem:share:qq',
            'menuItem:share:weiboApp',
            'menuItem:favorite',
            'menuItem:share:facebook',
            'menuItem:share:QZone',
            'menuItem:copyUrl'
          ]
        })
      }, 500)
    })
  },

  // 隐藏菜单内容
  hideMenu (menuList, defer = 500) {
    if (!wx.ready) return this.setPendingList((menuList, defer) => { this.hideMenu(menuList, defer) })
    wx.ready(() => {
      setTimeout(() => {
        wx.hideMenuItems({
          menuList: [
            ...menuList
          ]
        })
      }, defer)
    })
  },

  // 隐藏一些基础按钮
  hideBasicMenu (menuList = ['menuItem:copyUrl']) {
    if (!wx.ready) return this.setPendingList(() => { this.hideBasicMenu() })
    if (wx.ready) {
      wx.ready(() => {
        setTimeout(() => {
          wx.hideMenuItems({
            menuList
          })
        }, 500)
      })
    }
  },

  // 设置sdk缓冲队列
  setPendingList (fn) {
    getApp().globalData.sdkPendingList.push(fn)
  }
}