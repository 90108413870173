/*
* 获取多少天后或多少天前
* 日期 返回格式 2019/11/28
*/
import { Toast } from 'vant'
export function countDay (mount) {
  let today = new Date()
  today.setDate(today.getDate() + mount)
  return today.getFullYear() + '/' + (today.getMonth() + 1) + '/' +
    today.getDate()
}

export function fquery (query = window.location.search.substr(1)) {
  const reg = /([^=&\s]+)[=\s]*([^&\s]*)/g
  let obj = {}
  while (reg.exec(query)) obj[RegExp.$1] = RegExp.$2
  return obj
}

export function wxPay (data) {
  function onBridgeReady(data) {
    WeixinJSBridge.invoke('getBrandWCPayRequest', {
      appId: data.appId,  //公众号ID，由商户传入    
      timeStamp: data.timeStamp,   //时间戳，自1970年以来的秒数    
      nonceStr: data.nonceStr,      //随机串    
      package: data.pkg,
      signType: data.signType,     //微信签名方式：    
      paySign: data.paySign //微信签名
    },
    function(res) {
      if (res.err_msg == 'get_brand_wcpay_request:ok') {
        // 使用以上方式判断前端返回,微信团队郑重提示：
        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        console.log(res, '支付成功')
        Toast.success('支付成功')
      }
    });
  }
  if (typeof WeixinJSBridge == 'undefined') {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', onBridgeReady(data), false);
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', onBridgeReady(data));
      document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(data));
    }
  } else {
    onBridgeReady(data);
  }	
}