/*
 * @Descripttion:
 * @version:
 * @Author: fengxl
 * @Date: 2020-11-26 14:11:34
 * @LastEditors: fengxl
 * @LastEditTime: 2021-04-19 14:48:02
 */
import AMapLoader from '@amap/amap-jsapi-loader' // 引入地图
export const mapMinxin = {
  data: () => {
    return {
      // 地图的数据
      mapConfig: {
        mapKey: '1c008dd3eb5083d298a997c8319b5874', // 高德地图Web端开发者Key
        center: [104.065735, 30.659462], // 地图中心点 默认为成都
        zoom: 17 // 地图层级(展示缩放大小)
      },
      thisPosition: {}, // 当前定位对象
      conter: {}, // 当前定位经纬度对象
      mainConter: [], // 当前定位经纬度数组
      cityInfo: {}, // 当前位置的城市信息
      resultList: [], // 查询到的地址信息
      // markerList: [], // 地图绘制点
      addrArr: [],
      showSignInBtn: true, // 是否展示签到按钮
      getPositionTime: {},
      POIType: ['商务住宅', '餐饮服务', '住宿服务', '生活服务', '医疗保健服务', '科教文化服务', '政府机构及社会团体', '风景名胜', '汽车服务']
    }
  },
  computed: {
  },
  methods: {
    // 初始化数据
    initData () {
      // 先判断地图是否需要初始化
      if (window && window.AMap) {
        console.log('1aaaaaa', window.AMap)
        // 需要定位
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true, // 禁用背景点击
          message: '定位中',
          loadingType: 'spinner'
        })
        window && window.AMap && this.getWebPosition()
      } else {
        console.log(123456)
        window && window.geMap && geMap.destroy()
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true, // 禁用背景点击
          message: '定位中',
          loadingType: 'spinner'
        })
        this.initMap()
      }
    },
    // 初始化地图
    initMap () {
      // 地图未加载完成时  不能退出  以免浏览器奔溃
      window && window.geMap && geMap.destroy()
      console.log('1111')
      let _this = this
      AMapLoader.load({
        key: this.mapConfig.mapKey,
        version: '1.4.15' // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      })
        .then(AMap => {
          window.AMap = AMap
          _this.getWebPosition()
        })
        .catch(e => {
          // 地图初始化加载失败
          _this.$toast.clear()
          _this.$toast.fail('地图初始化加载失败')
        })
    },
    // 使用远程定位，见 remogeo.js
    remogeo () {
      const remoGeoLocationScript = document.getElementById('remoGeoLocationScript')
      if (!remoGeoLocationScript) {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.src = 'https://cf-assets.oss-cn-hangzhou.aliyuncs.com/qw-scrm/amap/remoGeoLocation.js'
          script.onload = () => {
            script.id = 'remoGeoLocationScript'
            resolve()
          }
          script.onerror = (e) => {
            reject(e)
          }
          document.body.appendChild(script)
        })
      }
    },
    // 获取用户定位信息
    getWebPosition () {
      let ua = window.navigator.userAgent.toLowerCase()
      if (this.isPhone && ua.match(/MicroMessenger/i) && ua.indexOf('wxwork') > -1) {
        this.getPosition()
      } else {
        this.getGDPosion()
      }
      // this.getPosition()
    },
    // 通过企微获取定位信息
    getPosition () {
      const _this = this
      this.getPositionTime = setTimeout(() => {
        _this.$toast.clear()
        _this.$toast.fail('获取定位信息异常，请检查定位权限')
        _this.showSignInBtn = false
      }, 15000)
      try {
        wx.getLocation({
          type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02' 高德默认使用的是 gcj02
          success: function (res) {
            // var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
            // var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
            // var speed = res.speed; // 速度，以米/每秒计
            // var accuracy = res.accuracy; // 位置精度
            clearTimeout(_this.getPositionTime)
            console.log('获取定位成功', res)
            _this.thisPosition = res
            let mapDom = 'signMapContainer-' + _this.$router.history.current.name
            if (_this.selectAddressType === 'customer' && _this.selectAddressInfo.longitude && _this.selectAddressInfo.latitude) {
              _this.conter = { lng: _this.selectAddressInfo.longitude, lat: _this.selectAddressInfo.latitude }
              _this.mainConter = [_this.selectAddressInfo.longitude, _this.selectAddressInfo.latitude]
              window && window.geMap && geMap.destroy()
              window.geMap = new AMap.Map(mapDom, {
                center: [_this.selectAddressInfo.longitude, _this.selectAddressInfo.latitude],
                zoom: _this.mapConfig.zoom
              })
            } else {
              _this.conter = { lng: res.longitude, lat: res.latitude }
              _this.mainConter = [res.longitude, res.latitude]
              window && window.geMap && geMap.destroy()
              window.geMap = new AMap.Map(mapDom, {
                center: [res.longitude, res.latitude],
                zoom: _this.mapConfig.zoom
              })
            }
            // 获取附近建筑物
            _this.getSearch()
          },
          fail: function (err) {
            console.log('获取定位失败', err)
            clearTimeout(_this.getPositionTime)
            _this.getGDPosion()
          }
        })
      } catch (err) {
        clearTimeout(_this.getPositionTime)
        _this.$toast.clear()
        _this.$toast.fail(_this.selectAddressType === 'customer' ? '获取定位信息失败' : '获取定位信息失败，无法签到')
        _this.showSignInBtn = false
        console.log('获取当前定位信息', err)
      }
    },
    // 通过高德获取定位信息
    getGDPosion () {
      console.log('gd')
      const _this = this
      // 获取定位信息
      window && window.AMap && AMap.plugin('AMap.Geolocation', function () {
        let keys = (window.geolocation && Object.keys(window.geolocation)) || []
        if (keys.length === 0) {
          window.geolocation = new AMap.Geolocation({
            enableHighAccuracy: true, // 是否使用高精度定位，默认：true
            timeout: 10000, // 设置定位超时时间，默认：无穷大
            showButton: false, // 显示定位按钮，默认：true
            buttonOffset: new AMap.Pixel(10, 20), // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
            zoomToAccuracy: true, //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            buttonPosition: 'RB' //  定位按钮的排放位置,  RB表示右下
          })
        }
        // 解决ios 4g 网络获取不到经纬度的问题
        // ios环境切换到使用远程https定位
        if (AMap.UA.ios) {
          // 使用远程定位，见 remogeo.js
          var remoGeo = new RemoGeoLocation()
          // 替换方法
          navigator.geolocation.getCurrentPosition = function () {
            return remoGeo.getCurrentPosition.apply(remoGeo, arguments)
          }
          // 替换方法
          navigator.geolocation.watchPosition = function () {
            return remoGeo.watchPosition.apply(remoGeo, arguments)
          }
        }
        window.geolocation.getCurrentPosition(function (status, result) {
          if (status === 'complete') {
            _this.thisPosition = result
            let mapDom = 'signMapContainer-' + _this.$router.history.current.name
            if (_this.selectAddressType === 'customer' && _this.selectAddressInfo.longitude && _this.selectAddressInfo.latitude) {
              _this.conter = { lng: _this.selectAddressInfo.longitude, lat: _this.selectAddressInfo.latitude }
              _this.mainConter = [_this.selectAddressInfo.longitude, _this.selectAddressInfo.latitude]
              window && window.geMap && geMap.destroy()
              window.geMap = new AMap.Map(mapDom, {
                center: [_this.selectAddressInfo.longitude, _this.selectAddressInfo.latitude],
                zoom: _this.mapConfig.zoom
              })
            } else {
              _this.conter = { lng: result.position.lng, lat: result.position.lat }
              _this.mainConter = [result.position.lng, result.position.lat]
              window && window.geMap && geMap.destroy()
              window.geMap = new AMap.Map(mapDom, {
                center: [result.position.lng, result.position.lat],
                zoom: _this.mapConfig.zoom
              })
            }
            // 获取附近建筑物
            _this.getSearch()
          } else {
            _this.$toast.clear()
            _this.$toast.fail(_this.selectAddressType === 'customer' ? '获取定位信息失败' : '获取定位信息失败，无法签到')
            _this.showSignInBtn = false
            if (_this.selectAddressType === 'customer') {
              setTimeout(() => {
                _this.$router.back()
              }, 1000)
            }
          }
        })
      })
    },
    // 初始化地图节点
    initMapDom () {
      let mapDom = 'signMapContainer-' + this.$router.history.current.name
      window && window.geMap && geMap.destroy()
      window.geMap = new AMap.Map(mapDom, {
        center: [this.conter.lng, this.conter.lat],
        zoom: this.mapConfig.zoom
      })
      this.drawMarker(this.conter)
    },
    // 获取定位附近建筑物
    getSearch (data) {
      const _this = this
      let obj = {
        pageSize: 30, // 单页显示结果条数
        pageIndex: 1, // 页码
        city: '全国', // 兴趣点城市
        citylimit: true, // 是否强制限制在设置的城市内搜索
        extensions: 'all'
      }
      if (data) {
        obj.citylimit = false
      } else {
        obj.type = this.POIType.join('|')
      }
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        message: '获取周边建筑物信息中',
        loadingType: 'spinner'
      })
      AMap && AMap.plugin(['AMap.PlaceSearch'], function () {
        // 构造地点查询类
        let keys = (window.placeSearch && Object.keys(window.placeSearch)) || []
        if (keys.length === 0) {
          window.placeSearch = new AMap.PlaceSearch(obj)
        }
        if ((data && _this.selectAddressType === 'customer') || (_this.selectAddressType === 'customer' && _this.selectAddressInfo.longitude && _this.selectAddressInfo.latitude)) {
          let _name = data || _this.selectAddressInfo.address
          _this.querySearchAddress(_name)
        } else {
          window.placeSearch.searchNearBy(data, _this.mainConter, 300, function (status, result) {
            _this.handleResultData(result, data)
          })
        }
      })
    },
    // 经纬度逆向转码为地址
    getAddress (resultObj, data) {
      const _this = this
      AMap.plugin('AMap.Geocoder', () => {
        let geocoder = new AMap.Geocoder()
        geocoder.getAddress(_this.mainConter, function (status, result) {
          if (status === 'complete' && result.regeocode) {
            let _addressInfo = {}
            _addressInfo.location = {}
            _addressInfo.location.lng = _this.mainConter[0]
            _addressInfo.location.lat = _this.mainConter[1]
            _addressInfo.pname = result.regeocode.addressComponent.province || ''
            _addressInfo.cityname = result.regeocode.addressComponent.city || ''
            _addressInfo.adname = result.regeocode.addressComponent.district || ''
            _addressInfo.name = result.regeocode.formattedAddress || ''
            _addressInfo.address = ''
            _addressInfo.pcode = parseInt(result.regeocode.addressComponent.adcode.substring(0, 2) + '0000') // 省 province
            _addressInfo.citycode = parseInt(result.regeocode.addressComponent.adcode.substring(0, 4) + '00') // 市 city
            _addressInfo.adcode = parseInt(result.regeocode.addressComponent.adcode) // 区 district
            if (!resultObj.info || resultObj.info !== 'OK') {
              resultObj = {
                info: 'OK',
                poiList: {
                  pois: []
                }
              }
            }
            resultObj.poiList.pois.unshift(_addressInfo)
            _this.searchEnd(resultObj, data)
          }
        })
      })
    },
    // 处理查询出来的数据
    handleResultData (result, data) {
      let arr = (result && result.poiList && result.poiList.pois && result.poiList.pois) || []
      let _pois = []
      // 如果查询出来的数据量为零，则直接走高德地图的api转换经纬度为地址信息
      this.getAddress(result, data)
      if (!(!result.info || result.info !== 'OK' || arr.length === 0)) {
        this.POIType.forEach(poi => {
          arr.forEach(item => {
            if (item.type.indexOf(poi) !== -1) {
              _pois.push(item)
            }
          })
        })
        if (_pois.length !== 0) {
          result.poiList.pois = _pois
        }
      }
    },
    // 绘制地图标记
    drawMarker (data) {
      // 清除所有标记
      window.geMap.clearMap && window.geMap.clearMap()
      let marker = new AMap.Marker({
        position: new window.AMap.LngLat(data.lng, data.lat),
        offset: new AMap.Pixel(0, 0),
        title: '测试标记点'
      })
      // this.markerList.push(marker)
      window.geMap.add(marker)
      this.showSignInBtn = true
      this.$toast.clear()
    },
    // 获取点与点之间的距离
    getDistance (customerAddress) {
      let p2 = [this.addressInfo.location.lng, this.addressInfo.location.lat]
      // 返回 p1 到 p2 间的地面距离，单位：米
      return AMap.GeometryUtil.distance(customerAddress, p2)
    },
    // PC搜索地址的方法
    querySearchAddress (queryString) {
      this.$get(
        'https://restapi.amap.com/v3/assistant/inputtips?s=rsv3&key=9d497b31eba4bb449a6cae0ce29ab749&keywords=' +
          queryString,
        {}
      ).then(res => {
        if (res.tips && res.tips.length > 0) {
          let arr = []
          for (let i = 0; i < res.tips.length; i++) {
            let j = res.tips[i]
            // id不存在 赋值id
            if (j.id === [] || j.id.length === 0 || !j.id) {
            } else {
              j.addrStr = this.$deepClone(j.name)
              j.name = `${j.district || ''} ${j.address || ''} ${j.name || ''}`
              arr.push(j)
            }
          }
          this.addrArr = arr.map(item => {
            let pcode = parseInt(item.adcode.substring(0, 2) + '0000')
            let ccode = parseInt(item.adcode.substring(0, 4) + '00')
            let adcode = parseInt(item.adcode)
            if (item.location) {
              return {
                location: {
                  lng: parseFloat(item.location.split(',')[0]),
                  lat: parseFloat(item.location.split(',')[1])
                },
                pname: this.getProvinceName(pcode),
                cityname: this.getCityName(ccode),
                adname: this.getCountyName(adcode),
                name: item.addrStr,
                address: item.address.length === 0 ? '' : item.address,
                pcode: pcode,
                citycode: ccode,
                adcode: adcode
              }
            } else {
              return undefined
            }
          })

          this.addrArr = this.addrArr.filter(item => { return item !== undefined })

          this.searchEnd({ info: 'OK', poiList: { pois: this.addrArr } }, queryString)
        } else {
          this.addrArr = []
        }
      })
    },
    // 寻找省
    getProvinceName (code) {
      let name = ''
      for (let i in this.provinceJs.province_list) {
        if (i === code + '') {
          name = this.provinceJs.province_list[i]
          break
        }
      }
      return name
    },
    // 寻找市
    getCityName (code) {
      let name = ''
      for (let i in this.provinceJs.city_list) {
        if (i === code + '') {
          name = this.provinceJs.city_list[i]
          break
        }
      }
      return name
    },
    // 寻找县
    getCountyName (code) {
      let name = ''
      for (let i in this.provinceJs.county_list) {
        if (i === code + '') {
          name = this.provinceJs.county_list[i]
          break
        }
      }
      return name
    }
  }
}
