/**
 * 订单
 * Created by wqs565 on 2020/5/18 14:23.
 */
import axios from '../../api'
// 入驻前调用信息
export const beforeOrgInfo = data => {
  return axios({
    url: 'gateway/org/info',
    method: 'post',
    data
  })
}

// 机构入驻
export const orgSettled = data => {
  return axios({
    url: 'gateway/org/settle',
    method: 'post',
    data
  })
}

// 签署合同验证码确认
export const orgSettledConfirm = data => {
  return axios({
    url: 'gateway/org/confirm',
    method: 'post',
    data
  })
}

// 机构入驻验证码
export const orgSmsSend = data => {
  return axios({
    url: 'gateway/org/sms/send',
    method: 'post',
    data
  })
}

// 机构分期申请列表
export const orgList = data => {
  return axios({
    url: 'gateway/order/org/list',
    method: 'post',
    data
  })
}

// 提交机构分期申请
export const orgOrderCreate = data => {
  return axios({
    url: 'gateway/order/org/create',
    method: 'post',
    data
  })
}

// 机构分期订单列表
export const orderList = data => {
  return axios({
    url: 'gateway/order/org/query',
    method: 'post',
    data
  })
}

// 机构分期签署
export const orgPeriodSmsSend = data => {
  return axios({
    url: 'gateway/order/org/sms/send',
    method: 'post',
    data
  })
}

// 确认签署
export const orgPeriodConfirm= data => {
  return axios({
    url: 'gateway/order/org/confirm',
    method: 'post',
    data
  })
}

// 机构订单详情
export const orderOrgInfo = data => {
  return axios({
    url: 'gateway/order/org/info',
    method: 'post',
    data
  })
}

// 添加担保人
export const orgGuaranteeAdd = data => {
  return axios({
    url: 'gateway/order/org/guarantee/add',
    method: 'post',
    data
  })
}

// 确认担保
export const contractConfirm = data => {
  return axios({
    url: 'gateway/order/org/guarantee/contract/confirm',
    method: 'post',
    data
  })
}

// 发送短信
export const contractSmsSend = data => {
  return axios({
    url: 'gateway/order/org/guarantee/contract/sms/send',
    method: 'post',
    data
  })
}

// 北辰银行签署
export const bankSmsSend = data => {
  return axios({
    url: 'gateway/order/send/apply/msg',
    method: 'post',
    data
  })
}

// 确认签署银行
export const bankConfirm = data => {
  return axios({
    url: 'gateway/order/confirm/apply/msg',
    method: 'post',
    data
  })
}

// 移除担保人
export const orgGuaranteeRemove = data => {
  return axios({
    url: 'gateway/order/org/guarantee/remove',
    method: 'post',
    data
  })
}

// 完成签署
export const orgSignFinish = data => {
  return axios({
    url: 'gateway/order/org/finish',
    method: 'post',
    data
  })
}

// 人脸识别