/*
 * @Author: wangqs
 * @Email: wqs565@163.com
 * @Date: 2018-05-21 10:56:18
 * @Last Modified by: wangqs
 * @Last Modified time: 2021-04-10 14:12:52
 * @Description: 将 axios 封装成 Vue 的插件使用
 */

import apiList from './interface'

const install = Vue => {
  if (install.installed) { return }
  install.installed = true

  Object.defineProperties(Vue.prototype, {
    $http: {
      get () {
        return apiList
      }
    }
  })
}

export default install
