/*
 * @Author: wangqs 
 * @Date: 2021-06-20 23:03:05 
 * @Last Modified by: wangqs
 * @Last Modified time: 2021-06-21 17:10:03
 */
const zoom = (maxWidth, baseWidth, tag) => {
  let currWidth = window.screen.width
  const $app = document.querySelector(tag)
  if (currWidth < maxWidth) {
    $app.style.zoom = currWidth / baseWidth
  } else {
    $app.style.zoom = 1
  }
  $app.parentNode.style.cssText = `max-width: ${maxWidth}px;margin: 0 auto;`
}

window.onresize = () => {
  zoom(740, 375, 'body')
}

zoom(740, 375, 'body')
