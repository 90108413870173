/*
 * @Author: wangqs 
 * @desc 还款
 * @Date: 2021-07-26 22:29:26 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-04-24 10:19:56
 */
import axios from '../../api'
// 当前账单
export const repayBill = data => {
  return axios({
    url: 'gateway/repayment/bill',
    method: 'post',
    data
  })
}

// 全部待还账单 
export const repayBillAll = data => {
  return axios({
    url: 'gateway/repayment/bill/all',
    method: 'post',
    data
  })
}

// 历史账单
export const billHistory = data => {
  return axios({
    url: 'gateway/repayment/bill/history',
    method: 'post',
    data
  })
}

// 历史账单详情
export const historyDetail = data => {
  return axios({
    url: 'gateway/repayment/bill/history/detail',
    method: 'post',
    data
  })
}

// 还款本期账单
export const nowBill = data => {
  return axios({
    url: 'gateway/repayment/repay',
    method: 'post',
    data
  })
}

// 还款-全部待还
export const repayWaitAll = data => {
  return axios({
    url: 'gateway/repayment/repayAll',
    method: 'post',
    data
  })
}

// 普通还款 银行卡信息
export const bankRepayInfo = data => {
  return axios({
    url: 'gateway/repayment/repay/bank',
    method: 'post',
    data
  })
}

// 全部还款银行信息
export const bankAllRepayInfo = data => {
  return axios({
    url: 'gateway/repayment/repayAll/bank',
    method: 'post',
    data
  })
}
