import axios from './api'
import * as userApi from './modules/user/index.js'
import * as homeApi from './modules/home/index.js'
import * as orderApi from './modules/order/index.js'
import * as repayApi from './modules/repayment/index.js'
import * as orgApi from './modules/org/index.js'

// 登录
export const login = data => {
  return axios({
    url: 'api/sign/login',
    method: 'post',
    data
  })
}

// 微信登录 参数 url
export const wxlogin = data => {
  return axios({
    url: 'gateway/wx/url',
    method: 'post',
    data
  })
}

export default {
  login,
  wxlogin,
  userApi,
  homeApi,
  orderApi,
  repayApi,
  orgApi
}
