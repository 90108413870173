/*
 * @Author: wangqs 
 * @Date: 2021-06-20 23:18:35 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-04-26 10:33:51
 */

import Vue from 'vue'
import Vuex from 'vuex'
// import CreateLogger from 'vuex/dist/logger'
import modules from './modules/index'
Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'
export default new Vuex.Store({
  mutations: {
    // 全局loading状态
    setFullscreenLoading (state, flag) {
      state.fullscreenLoading = flag
    },
    setClassifyList (state, flag) {
      state.classifyList = flag
    },
    setAllLastAmount (state, flag) {
      state.allLastAmount = flag
    }  
  },
  actions: {
  },
  state: {
    fullscreenLoading: false,
    classifyList: [],
    allLastAmount: 0
  },
  modules,
  strict: debug,
  // plugins: debug ? [CreateLogger()] : []
  plugins: []
})
