import $http from '../axios/interface.js';
import { fquery } from './common.js'
import { Toast } from 'vant'

const urlObj = fquery(window.location.hash.split('?')[1])

const toAuth = async () => {
  console.log(urlObj, 'obj')
  if (urlObj.code) {
    Toast.loading({
      message: '加载中',
      forbidClick: true,
      duration: 0
    });
    let res = await $http.userApi.wxLogin({code: urlObj.code})
    if (res.code === 200) {
      Toast.clear()
      window.localStorage.setItem('userData', JSON.stringify(res.msg || {}))
      let url = (urlObj.state || 'home/index').replace(/-/g, '/').replace(/@@@/g, '&').replace(/@@/g, '=').replace(/@/g, '?')
      if (urlObj.checkCode) {
        code = urlObj.checkCode
        url += `?${urlObj.checkCode}&checkType=${urlObj.checkType}`
      }
      console.log(window.location.origin + '/#/' + url, '====')
      window.location.replace(window.location.origin + '/#/' + url)
    }
  }
}

export default toAuth